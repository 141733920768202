                        `<o id="${i}" url="http://www.przykładowylink.pl/id-1" price="999.99" avail="1" weight="4" stock="2" basket="1">` +
                        `<cat>` +
                            `<![CDATA[${this.products[i].category}]]>` +
                            `</cat>` +
                            `<name>` +
                            `<![CDATA[${this.products[i].name}]]>` +
                            `</name>` +
                            `<imgs>` +
                            `<main url="http://www.przykładowylink.pl/id-1.jpg"/>` +
                            `<i url="http://www.przykładowylink.pl/id-1/1.jpg"/>` +
                            `<i url="http://www.przykładowylink.pl/id-1/2.jpg"/>` +
                            `<i url="http://www.przykładowylink.pl/id-1/3.jpg"/>` +
                            `</imgs>` +
                            `<desc>`;


                            `</desc>` +
                            `<attrs>` +
                            `<a name="Producent">
                                <![CDATA[${this.products[i].manufacturer}]]>
                            </a>` +
                            `<a name="EAN">
                                <![CDATA[00000000]]>
                            </a>` +
                            `<a name="Kod producenta">
                                <![CDATA[${this.products[i].model}]]>
                            </a>` +
                            `<a name="Seria procesora">
                                <![CDATA[${this.products[i].specs.processorType.value}]]>
                            </a>` +
                            `<a name="Stan ekranu">
                                <![CDATA[${this.products[i].specs.screenCondition.value}]]>
                            </a>` +
                            `<a name="Stan obudowy">
                                <![CDATA[${this.products[i].specs.casingCondition.value}]]>
                            </a>` +
                            `<a name="Gwarancja">
                                <![CDATA[${this.products[i].specs.warrantyType.value}]]>
                            </a>` +
                            `<a name="Procesor">
                                <![CDATA[${this.products[i].specs.processor.value}]]>
                            </a>` +
                            `<a name="Taktowanie">
                                <![CDATA[${this.products[i].specs.cpuSpeed.value}]]>
                            </a>` +
                            `<a name="Ilość rdzeni">
                                <![CDATA[${this.products[i].specs.coreNum.value}]]>
                            </a>` +
                            `<a name="Ilość pamięci RAM">
                                <![CDATA[${this.products[i].specs.ram.value}]]>
                            </a>` +
                            `<a name="Typ pamięci RAM">
                                <![CDATA[${this.products[i].specs.ramType.value}]]>
                            </a>` +
                            `<a name="Dysk">
                                <![CDATA[${this.products[i].specs.hardDrive.value}]]>
                            </a>` +
                            `<a name="Typ dysku">
                                <![CDATA[${this.products[i].specs.typeOfHardrive.value}]]>
                            </a>` +
                            `<a name="Licencja">
                                <![CDATA[${this.products[i].specs.os.value}]]>
                            </a>` +
                            `<a name="Typ licencji">
                                <![CDATA[${this.products[i].specs.coaType.value}]]>
                            </a>` +
                            `<a name="Zainstalowany system">
                                <![CDATA[${this.products[i].specs.installedOS.value}]]>
                            </a>` +
                            `<a name="Ekran dotykowy">
                                <![CDATA[${this.products[i].specs.displayTouchable.value}]]>
                            </a>` +
                            `<a name="Rozdzielczość ekranu">
                                <![CDATA[${this.products[i].specs.displayResolution.value}]]>
                            </a>` +
                            `<a name="Przekątna ekranu">
                                <![CDATA[${this.products[i].specs.displaySize.value}]]>
                            </a>` +
                            `<a name="Powłoka matrycy">
                                <![CDATA[${this.products[i].specs.displayType.value}]]>
                            </a>` +
                            `<a name="Rodzaj karty graficznej">
                                <![CDATA[${this.products[i].specs.graphicsType.value}]]>
                            </a>` +
                            `<a name="Model karty graficznej">
                                <![CDATA[${this.products[i].specs.graphics.value}]]>
                            </a>` +
                            `<a name="Złącza zewnętrzne">
                                <![CDATA[${this.products[i].specs.allConnectionsOutside.value}]]>
                            </a>` +
                            `<a name="Napęd">
                                <![CDATA[${this.products[i].specs.dvd.value}]]>
                            </a>` +
                            `<a name="Kamera">
                                <![CDATA[${this.products[i].specs.camera.value}]]>
                            </a>` +
                            `<a name="Komunikacja">
                                <![CDATA[${this.products[i].specs.networkWireless.value}]]>
                            </a>` +
                            `<a name="Bateria">
                                <![CDATA[${this.products[i].specs.battery.value}]]>
                            </a>` +
                            `<a name="Klawiatura">
                                <![CDATA[${this.products[i].specs.keyboard.value}]]>
                            </a>` +
                            `<a name="W zestawie">
                                <![CDATA[${this.products[i].specs.inPack.value}]]>
                            </a>` +
                           `</attrs>` +
                        `</o>` 